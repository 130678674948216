.socials_sidebar {
  height: fit-content;
  width: 50px;

  position: fixed;
  bottom: 0;
  left: 2%;

  box-sizing: border-box;

  z-index: 10;
}

.socials_sidebar aside {
  width: 100%;
  height: 100%;
  list-style: none;
  display: flex;
  flex-direction: column;
  align-items: center;

  box-sizing: border-box;
}

.socials_icon {
  color: gray;
  box-shadow: 1px solid rgba(0, 0, 0, 0.7);
  margin: 10px 0px;
  font-size: 22px;

  cursor: pointer;
  transition: 0.1s;
}

.socials_icon:hover {
  transform: translateY(-5px);
  color: var(--main_color_theme);
}

.socials_divider::after {
  content: "";
  display: block;
  height: 120px;
  width: 1px;
  margin: 0px auto;
  margin-top: 12px;
  background-color: rgb(170, 170, 170);
}

.theme_sidebar {
  position: fixed;
  width: 25px;
  height: fit-content;
  right: 0px;
  bottom: 100px;
  z-index: 10;

  border-left: 3px solid var(--main_color_theme);

  display: flex;
  flex-direction: column;
  align-items: center;
}

.color_theme {
  height: 30px;
  width: 100%;
  cursor: pointer;
}

.color_theme.theme_cyan {
  background-color: #4cc2d9;
}

.color_theme.theme_white {
  background-color: #ffffff;
}

.color_theme.theme_green {
  background-color: #2cd938;
}

.color_theme.theme_pink {
  background-color: #e34b6c;
}

.color_theme.theme_purple {
  background-color: #6b49c9;
}

@media screen and (max-width: 700px) {
  .socials_sidebar {
    visibility: hidden;
  }

  .theme_sidebar {
    flex-direction: row;
    width: 100%;
    height: 25px;
    position: relative;
    top: auto;
    right: auto;
    bottom: auto;

    margin-top: auto;

    border-left: none;
    border-top: 3px solid var(--main_color_theme);
  }

  .color_theme {
    height: 100%;
    width: 20%;
  }
}
